<template>
  <div>
    <div v-if="!isLoading" class="box box-default">
      <div class="box-header">
        <div class="flex align-items-center justify-between">
          <h3 class="box-title">AltCoin Stats</h3>
          <v-select v-model="altCoinID" :options="coins" :reduce="coin => coin.ID" class="ml-8" label="Name"
            placeholder="Select AltCoin" style="min-width: 200px;"></v-select>
        </div>
      </div>
      <div class="box-body no-padding">
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-condensed">
            <thead>
              <tr>
                <th>Stats ID</th>
                <th>Coin</th>
                <th>Error</th>
                <th>ID</th>
                <th>Version</th>
                <th>Protocol Version</th>
                <th>Wallet Version</th>
                <th>Balance</th>
                <th>Blocks</th>
                <th>Time offset</th>
                <th>Connections</th>
                <th>Proxy</th>
                <th>Difficulty</th>
                <th>Testnet</th>
                <th>Key Pool Oldest</th>
                <th>Key Pool Size</th>
                <th>Key Pool Size Hd Internal</th>
                <th>Paytxfee</th>
                <th>Mininput</th>
                <th>Darksend Balance</th>
                <th>Newmint</th>
                <th>Stake</th>
                <th>Money Supply</th>
                <th>IP</th>
                <th>Powblocks</th>
                <th>Powblocksleft</th>
                <th>Relayfee</th>
                <th>Errors</th>
                <th>Difficulty sha256d</th>
                <th>Difficulty scrypt</th>
                <th>Difficulty groestl</th>
                <th>Difficulty skein</th>
                <th>Difficulty qubit</th>
                <th>Pow Algo Id</th>
                <th>Pow Algo</th>
                <th>Unlocked_until</th>
                <th>Unconfirmed Balance</th>
                <th>Locked</th>
                <th>Encrypted</th>
                <th>Cakenet</th>
                <th>Newpowmint</th>
                <th>Newposmint</th>
                <th>Allposmint</th>
                <th>Allpowmint</th>
                <th>Mode</th>
                <th>Okcashbalance</th>
                <th>Reserve</th>
                <th>Data Received</th>
                <th>Data Sent</th>
                <th>Deprecation Warning</th>
                <th>Walletname</th>
                <th>Unconfirmed_balance</th>
                <th>Immature_balance</th>
                <th>Txcount</th>
                <th>Hdmasterkeyid</th>
                <th>Zerocoinbalance</th>
                <th>zJEWsupply</th>
                <th>Privatesend_balance</th>
                <th>Obfuscation_balance</th>
                <th>Staking status</th>
                <th>Hdseedid</th>
                <th>Private_keys_enabled</th>
                <th>Staking_active</th>
                <th>Wallet_seeds</th>
                <th>Unconfirmed</th>
                <th>Immature</th>
                <th>zNPCsupply</th>
                <th>Build Version</th>
                <th>zCOLXsupply</th>
                <th>Services</th>
                <th>Avoid Reuse</th>
                <th>Scanning</th>
                <th>Created On</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="records.length > 0">
                <tr v-for="(record, index) in records" :key="index" @click="getChartData(record.AltCoinID)"
                  class="pointer">
                  <td>{{ record.StatsID }}</td>
                  <td class="no-wrap">{{ record.Name + ' (' + record.LongName + ')' }}</td>
                  <td>{{ record.error }}</td>
                  <td>{{ record.id }}</td>
                  <td>{{ record.version }}</td>
                  <td>{{ record.protocolversion }}</td>
                  <td>{{ record.walletversion }}</td>
                  <td>{{ record.balance }}</td>
                  <td>{{ record.blocks }}</td>
                  <td>{{ record.timeoffset }}</td>
                  <td>{{ record.connections }}</td>
                  <td>{{ record.proxy }}</td>
                  <td>{{ record.difficulty }}</td>
                  <td>{{ record.testnet }}</td>
                  <td>{{ record.keypoololdest }}</td>
                  <td>{{ record.keypoolsize }}</td>
                  <td>{{ record.keypoolsize_hd_internal }}</td>
                  <td>{{ record.paytxfee }}</td>
                  <td>{{ record.mininput }}</td>
                  <td>{{ record.darksend_balance }}</td>
                  <td>{{ record.newmint }}</td>
                  <td>{{ record.stake }}</td>
                  <td>{{ record.moneysupply }}</td>
                  <td>{{ record.ip }}</td>
                  <td>{{ record.powblocks }}</td>
                  <td>{{ record.powblocksleft }}</td>
                  <td>{{ record.relayfee }}</td>
                  <td>{{ record.errors }}</td>
                  <td>{{ record.difficulty_sha256d }}</td>
                  <td>{{ record.difficulty_scrypt }}</td>
                  <td>{{ record.difficulty_groestl }}</td>
                  <td>{{ record.difficulty_skein }}</td>
                  <td>{{ record.difficulty_qubit }}</td>
                  <td>{{ record.pow_algo_id }}</td>
                  <td>{{ record.pow_algo }}</td>
                  <td>{{ record.unlocked_until }}</td>
                  <td>{{ record.unconfirmedbalance }}</td>
                  <td>{{ record.locked }}</td>
                  <td>{{ record.encrypted }}</td>
                  <td>{{ record.cakenet }}</td>
                  <td>{{ record.newpowmint }}</td>
                  <td>{{ record.newposmint }}</td>
                  <td>{{ record.allposmint }}</td>
                  <td>{{ record.allpowmint }}</td>
                  <td>{{ record.mode }}</td>
                  <td>{{ record.okcashbalance }}</td>
                  <td>{{ record.reserve }}</td>
                  <td>{{ record.datareceived }}</td>
                  <td>{{ record.datasent }}</td>
                  <td>{{ record.deprecationWarning }}</td>
                  <td>{{ record.walletname }}</td>
                  <td>{{ record.unconfirmed_balance }}</td>
                  <td>{{ record.immature_balance }}</td>
                  <td>{{ record.txcount }}</td>
                  <td>{{ record.hdmasterkeyid }}</td>
                  <td>{{ record.zerocoinbalance }}</td>
                  <td>{{ record.zJEWsupply }}</td>
                  <td>{{ record.privatesend_balance }}</td>
                  <td>{{ record.obfuscation_balance }}</td>
                  <td>{{ record.staking_status }}</td>
                  <td>{{ record.hdseedid }}</td>
                  <td>{{ record.private_keys_enabled }}</td>
                  <td>{{ record.staking_active }}</td>
                  <td>{{ record.wallet_seeds }}</td>
                  <td>{{ record.unconfirmed }}</td>
                  <td>{{ record.immature }}</td>
                  <td>{{ record.zNPCsupply }}</td>
                  <td>{{ record.buildversion }}</td>
                  <td>{{ record.zCOLXsupply }}</td>
                  <td>{{ record.services }}</td>
                  <td>{{ record.avoid_reuse }}</td>
                  <td>{{ record.scanning }}</td>
                  <td class="no-wrap pr-16">{{ formatDate(record.CreatedOn) }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="72">No record found!</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
      </div>
    </div>
    <div class="box box-default" v-if="!isLoading && isGraphAvailable">
      <div class="box-header">
        <h3 class="box-title">Balance</h3>
      </div>
      <div class="box-body no-padding">
        <Highcharts :options="chartData" />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { mapGetters } from "vuex";
import { START_LOADING, STOP_LOADING } from "@/store/keys";
import { formatDate, saveNotification } from "@/lib/utils";
import Highcharts from "highcharts";
import { genComponent } from "vue-highcharts";

export default {
  name: "AltCoinStats",
  components: {
    Pagination,
    Highcharts: genComponent("Highcharts", Highcharts),
  },
  data() {
    return {
      records: [],
      coins: [],
      altCoinID: "",
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      chartData: null,
      isGraphAvailable: false,
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "currentUser",
    ]),
  },
  watch: {
    altCoinID(val) {
      if (val === null) {
        this.altCoinID = "";
      }
      this.getPaginatedRecords(1);
    },
  },
  methods: {
    updatePage(page) {
      this.getPaginatedRecords(page);
    },
    getPaginatedRecords(page = 1) {
      this.$store.commit(START_LOADING);
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=altcoin_stats`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          altCoinID: this.altCoinID,
          limit: Number.parseInt(this.meta.limit),
        },
      }).then((response) => {
        this.$store.commit(STOP_LOADING);
        if (response.status === 200) {
          this.records = response.data.data.records;
          this.coins = response.data.data.coins;
          this.meta = response.data.data.meta;
          if (this.altCoinID !== "") {
            this.isGraphAvailable = true;
            const graphData = response.data.data.graphData;
            const data = graphData.data.map(d => {
              return parseFloat(d);
            });
            this.chartData = {
              chart: {
                type: "line",
              },
              title: {
                text: "",
              },
              subtitle: {
                text: "",
              },
              accessibility: {
                announceNewData: {
                  enabled: true,
                },
              },
              xAxis: {
                categories: graphData.labels,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "Balance",
                },
              },
              series: [{
                name: "Balance",
                data: data,
              }],
            };
          }
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    formatDate(val) {
      return formatDate(val);
    },
    getChartData(id) {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=altcoin_stats_chart`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          altCoinID: id,
        },
      }).then(response => {
        this.isGraphAvailable = true;
        const graphData = response.data.data;
        const data = graphData.data.map(d => {
          return parseFloat(d);
        });
        this.chartData = {
          chart: {
            type: "line",
          },
          title: {
            text: "",
          },
          subtitle: {
            text: "",
          },
          accessibility: {
            announceNewData: {
              enabled: true,
            },
          },
          xAxis: {
            categories: graphData.labels,
          },
          yAxis: {
            min: 0,
            title: {
              text: "Balance",
            },
          },
          series: [{
            name: "Balance",
            data: data,
          }],
        };
      }).catch(error => {
        this.$toast.fire("", error.data.message, "error");
      });
    },
  },
  mounted() {
    this.getPaginatedRecords();
  },
  beforeDestroy() {
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style scoped></style>
